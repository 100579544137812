import React from "react";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/sales/Hero";
import Features from "../sections/sales/Features";
import Content1 from "../sections/sales/Content1";
import Content2 from "../sections/sales/Content2";
import Content3 from "../sections/sales/Content3";


const Sales = () => {
  const onClickInstantDemo = () => {
    if (window && window.INSTANT_DEMO) {
      window.INSTANT_DEMO.initiate_flow('f9b10ee5-7c98-46a6-bc3b-94b3f2eaa2a2')
    }

    if (window !== "undefined" && window.gtag) {
      window.gtag("event", "conversion", { send_to: 'AW-411216111/QZPVCKPc9_0BEO_RisQB' })
    }
  }

  return (
    <>
      <PageWrapper
        headerConfig={{
          isFluid: true,
        }}
      >
        <Hero onClickInstantDemo={onClickInstantDemo} />
        <Features />
        <Content1 />
        <Content2 />
        <Content3 />
      </PageWrapper>
    </>
  );
};

export default Sales;