import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Content = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "sales/outbound-analytics-no-border.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section bg-default-6 pb-13 pb-lg-21">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col
              sm="10"
              lg="6"
              className="offset-lg-1 order-lg-2 mb-9 mb-lg-0"
            >
              <div
                className="double-image-group-opacity rounded-10"
                style={{border: '8px solid #161C2D'}}
              >
                <Img
                  className="main-img w-100"
                  alt="Image 2"
                  fluid={data.heroImage.childImageSharp.fluid}
                />
              </div>
            </Col>
            <Col sm="10" lg="5" className="order-lg-1">
              <div className="content-text">
                <h2 className="gr-text-4 mb-7">
                  Increase your Qualified Leads via Outbound
                </h2>
                <p className="gr-text-8 mb-0">
                  Know who is watching your videos with detailed reporting, then tailor your follow up to book that meeting. 
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
