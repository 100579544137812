import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import outboundMagic from '../../assets/image/sales/outbound-magic-2.gif'

const Content = () => {
  return (
    <>
      <div className="content-section bg-default-6 pt-lg-15 pt-10 pb-13 pb-lg-21">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col sm="10" lg="7">
              <img
                className="gr-image-box-shadow mr-lg-9 rounded-10 mw-100"
                style={{border: '8px solid #161C2D'}}
                alt="Image 1"
                src={outboundMagic}
              />
            </Col>
            <Col sm="10" lg="5">
              <div className="content-text pt-11 pt-lg-0 pl-xl-7">
                <h2 className="gr-text-4 mb-7">
                  Create Magical Moments
                </h2>
                <p className="gr-text-8 mb-0">
                  The easiest way to be great at sales is to leave your prospects thinking “wow.” Providing them with real value from the first moment they interact with your brand is the easiest way to do that. 
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
